import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ApiService } from '../service/api/api.service';
import { EMPTY, concat, concatMap, delay, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../service/api/auth/auth.service';
import { Navigate } from '@ngxs/router-plugin';
import { HttpResponse } from '@angular/common/http';
import { patch } from '@ngxs/store/operators';

export interface authmodel {
  user: any;
  access_token: string;
  refresh_token: string;
  isTokenExpired: boolean;
}

export class Login {
  static readonly type = '[Login] AuthState';
  constructor(public loginpayload: { mobileno: string; password: string }) {}
}

export class SetAccessToken {
  static readonly type = '[SetAccessToken] AuthState';
  constructor(public token: string) {}
}

export class SetRefreshToken {
  static readonly type = '[SetRefreshToken] AuthState';
  constructor(public token: string) {}
}

export class UpdateRefreshToken {
  static readonly type = '[UpdateRefreshToken] AuthState';
}

export class IsTokenExpired {
  static readonly type = '[IsTokenExpired] AuthState';
}

export class Logout {
  static readonly type = '[Logout] AuthState';
}

@State<authmodel>({
  name: 'auth',
  defaults: {
    user: null,
    access_token: '',
    refresh_token: '',
    isTokenExpired: false,
  },
})
@Injectable()
export class AuthState {
  constructor(private authService: AuthService) {}

  @Selector()
  static getUser(state: authmodel): any {
    return state.user;
  }

  @Selector()
  static getToken(state: authmodel): string {
    return state.access_token;
  }

  @Selector()
  static isTokenExpired(state: authmodel): boolean {
    return state.isTokenExpired;
  }

  @Selector()
  static isToken(state: authmodel): boolean {
    return state.access_token !== null && state.access_token !== '';
  }

  @Action(Login)
  login(states: StateContext<authmodel>, action: Login) {
    return this.authService.login(action.loginpayload).pipe(
      delay(1000),
      concatMap((res: any) => {
        states.patchState({
          user: res.message.userDetail,
        });
        return states.dispatch([new Navigate(['/', 'home', 'dashboard'])]);
      })
    );
  }

  @Action(SetAccessToken)
  setAccessToken(states: StateContext<authmodel>, action: SetAccessToken) {
    const token = action.token.replace(/^Bearer\s+/i, '');
    states.patchState({
      access_token: token,
    });
  }

  @Action(SetRefreshToken)
  setRefreshToken(states: StateContext<authmodel>, action: SetRefreshToken) {
    states.patchState({
      refresh_token: action.token,
    });
  }

  @Action(UpdateRefreshToken)
  refreshToken(states: StateContext<authmodel>) {
    return this.authService.refreshToken(states.getState().refresh_token).pipe(
      concatMap((res: any) => {
        states.patchState({
          access_token: res.message.jwtToken,
        });
        return EMPTY;
      })
    );
  }

  @Action(IsTokenExpired)
  isTokenExpired(states: StateContext<authmodel>, action: IsTokenExpired) {
    return this.authService.isTokenExpired(states.getState().access_token).pipe(
      concatMap((res: any) => {
        states.patchState({
          isTokenExpired: res.message.isExpired,
        });
        return EMPTY;
      })
    );
  }

  @Action(Logout)
  logout(states: StateContext<authmodel>, action: Logout) {
    const refresh_token = states.getState().refresh_token;
    if (refresh_token !== null && refresh_token !== '') {
      return this.authService.logout(refresh_token).pipe(
        concatMap((res: any) => {
          console.log(res);
          states.setState(
            patch({
              access_token: '',
              refresh_token: '',
              isTokenExpired: false,
              user: null,
            })
          );
          return states.dispatch([new Navigate(['/', 'auth-login-cover'])]);
        })
      );
    } else {
      states.setState(
        patch({
          access_token: '',
          refresh_token: '',
          isTokenExpired: false,
          user: null,
        })
      );
      return states.dispatch([new Navigate(['/', 'auth-login-cover'])]);
    }
  }
}
