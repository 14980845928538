import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { LucideAngularModule } from 'lucide-angular';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import {
  InputSizeConfig,
  IconConfig,
  BorderConfig,
  BorderStyleConfig,
  BorderStyleShape,
  GroupConfig,
} from './custom-input.model';

@Component({
  selector: 'app-custom-input',
  standalone: true,
  imports: [
    CommonModule,
    LucideAngularModule,
    FormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  templateUrl: './custom-input.component.html',
  styleUrl: './custom-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: CustomInputComponent,
      multi: true,
    },
  ],
})
export class CustomInputComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  @Input() type: string = 'text';
  @Input() size: InputSizeConfig = InputSizeConfig.MD;
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() placeholder: string = '';
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() hidden: boolean = false;
  @Input() icon: IconConfig = {
    visibility: false,
    name: 'mail',
    direction: 'left',
  };
  @Input() border: BorderConfig = {
    style: BorderStyleConfig.DEFAULT,
    shape: BorderStyleShape.DEFAULT,
  };
  @Input() group: GroupConfig = {
    visibility: false,
    icon: '&#64;',
    direction: 'left',
  };
  @Input() row: number = 3;
  @Input() subtext: string = '';
  @Input() isTouched: boolean = false;
  @Input() mask: string = '';
  @Input() prefix: string = '';
  @Input() isSubmitted: boolean = false;
  @Input() containerClass: any;

  commonInputClasses = [
    'form-input',
    'focus:outline-none',
    'disabled:bg-slate-100',
    'disabled:text-slate-500',
    'disabled:border-slate-300',
    'dark:disabled:bg-zink-600',
    'dark:disabled:text-zink-200',
    'dark:text-zink-100',
    'dark:bg-zink-700',
    'placeholder:text-slate-400',
    'dark:placeholder:text-zink-200',
  ];
  iconDirectionClass = [
    'absolute',
    'size-4',
    'ltr:left-3',
    'rtl:right-3',
    'top-3',
    'text-slate-500',
    'dark:text-zink-200',
  ];
  inputClass: string = '';
  errors: string = '';
  currentValue: any = null;
  ctrl: any;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    switch (this.size) {
      case 'sm': {
        const inputClassArray = ['px-3', 'py-1', 'text-xs'];
        this.commonInputClasses = this.commonInputClasses.concat(
          ...inputClassArray
        );
        break;
      }
      case 'md': {
        break;
      }
      case 'lg': {
        const inputClassArray = ['px-5', 'py-3', 'text-15'];
        this.commonInputClasses = this.commonInputClasses.concat(
          ...inputClassArray
        );
        break;
      }
      default: {
        break;
      }
    }

    switch (this.border.style) {
      case 'default': {
        const inputClassArray = [
          'border-slate-200',
          'dark:border-zink-500',
          'focus:border-custom-500',
        ];
        this.commonInputClasses = this.commonInputClasses.concat(
          ...inputClassArray
        );
        break;
      }
      case 'transparent': {
        const inputClassArray = [
          'border-transparent',
          'dark:border-transparent',
          'focus:border-transparent',
        ];
        this.commonInputClasses = this.commonInputClasses.concat(
          ...inputClassArray
        );
        break;
      }
      case 'dashed': {
        const inputClassArray = [
          'border-dashed',
          'border-slate-200',
          'dark:border-zink-500',
          'focus:border-custom-500',
        ];

        this.commonInputClasses = this.commonInputClasses.concat(
          ...inputClassArray
        );
        break;
      }
      default: {
        const inputClassArray = [
          'border-slate-200',
          'dark:border-zink-500',
          'focus:border-custom-500',
        ];
        this.commonInputClasses = this.commonInputClasses.concat(
          ...inputClassArray
        );
        break;
      }
    }

    switch (this.border.shape) {
      case 'default': {
        break;
      }
      case 'rounded': {
        const inputClassArray = ['rounded-full'];
        this.commonInputClasses = this.commonInputClasses.concat(
          ...inputClassArray
        );
        break;
      }
      default: {
        break;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['icon']?.currentValue.visibility) {
      this.setIconDirectionClass(changes['icon'].currentValue.direction);
    }

    if (changes['group']?.currentValue.visibility) {
      this.setGroupDirectionClass(changes['group'].currentValue.direction);
    }

    if (changes['containerClass']?.currentValue) {
      this.containerClass = changes['containerClass'].currentValue;
    }

    if (changes['isSubmitted']?.currentValue == true) {
      this.isSubmitted = true;
    } else {
      this.isSubmitted = false;
    }
  }

  detectChange() {
    if (this.cdr) {
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    }
  }

  getInputClass() {
    return `${this.commonInputClasses.join(' ')}`;
  }

  getIconDirectionClass() {
    return `${this.iconDirectionClass.join(' ')}`;
  }

  setIconDirectionClass(direction: string) {
    if (direction == 'left') {
      this.iconDirectionClass = [
        'absolute',
        'size-4',
        'ltr:left-3',
        'rtl:right-3',
        'top-3',
        'text-slate-500',
        'dark:text-zink-200',
      ];

      const inputClassArray = ['ltr:pl-10', 'rtl:pr-10'];
      this.commonInputClasses = this.commonInputClasses.concat(
        ...inputClassArray
      );
    } else {
      this.iconDirectionClass = [
        'absolute',
        'size-4',
        'ltr:right-3',
        'rtl:left-3',
        'top-3',
        'text-slate-500',
        'dark:text-zink-200',
      ];

      const inputClassArray = ['ltr:pr-10', 'rtl:pl-10'];
      this.commonInputClasses = this.commonInputClasses.concat(
        ...inputClassArray
      );
    }
  }

  setGroupDirectionClass(direction: string) {
    if (direction == 'left') {
      const inputClassArray = ['ltr:rounded-l-none', 'rtl:rounded-r-none'];
      this.commonInputClasses = this.commonInputClasses.concat(
        ...inputClassArray
      );
    } else {
      const inputClassArray = ['ltr:rounded-r-none', 'rtl:rounded-l-none'];
      this.commonInputClasses = this.commonInputClasses.concat(
        ...inputClassArray
      );
    }
  }

  writeValue(value: any): void {
    this.currentValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange(value: any) {
    this.currentValue = value;
  }

  onTouched() {
    this.isTouched = true;
  }

  onBlur() {
    this.onTouched();
  }

  onInput() {
    this.onChange(this.currentValue);
  }

  validate(ctrl: FormControl) {
    this.ctrl = ctrl;
  }
}
