<div class="mb-3" [ngClass]="containerClass">
  @if(label) {
  <label for="inputText" class="inline-block mb-2 text-base font-medium dark:text-white">
    {{ label }}
    @if(required || ctrl?.errors?.required) {
    <span class="text-red-500">*</span>
    }
  </label>
  }
  <div class="relative flex items-center">
    @if(icon.visibility) {
    <lucide-angular [name]="icon.name" [class]="getIconDirectionClass()"></lucide-angular>
    } @if(group.visibility && group.direction == 'left') {
    <span
      class="inline-block px-3 py-2 border ltr:border-r-0 rtl:border-l-0 border-slate-200 bg-slate-100 dark:border-zink-500 dark:bg-zink-600 ltr:rounded-l-md rtl:rounded-r-md dark:text-white"
      [innerHTML]="group.icon">
      <!-- {{ group.icon }} -->
    </span>
    } @if(type == 'textarea') {
    <textarea id="textAreaText" [ngClass]="getInputClass()" [required]="required" [placeholder]="placeholder"
      [readOnly]="readonly" [rows]="row" [disabled]="disabled" (blur)="onBlur()" [(ngModel)]="currentValue"
      (input)="onInput()"></textarea>
    } @else {
    <input [type]="type" id="inputText" [ngClass]="getInputClass()" [required]="required" [placeholder]="placeholder"
      [readOnly]="readonly" [disabled]="disabled" (blur)="onBlur()" [mask]="mask" [prefix]="prefix"
      [(ngModel)]="currentValue" (input)="onInput()" />
    } @if(group.visibility && group.direction == 'right') {
    <span
      class="inline-block px-3 py-2 border border-l-0 border-slate-200 bg-slate-100 dark:border-zink-500 dark:bg-zink-600 ltr:rounded-r-md rtl:rounded-l-md dark:text-white"
      [innerHTML]="group.icon">
    </span>
    }
  </div>
  <div class="flex justify-between">
    @if (isSubmitted) { @if(ctrl.errors == null) {
    <div class="mt-1 text-sm text-green-500">Valid</div>
    } @else if(ctrl.errors['alphanumeric']) {
    <div class="mt-1 text-sm text-red-500">Alpha Numeric Only</div>
    } @else if(ctrl.errors['email']) {
    <div class="mt-1 text-sm text-red-500">Invalid Email</div>
    }@else if(ctrl.errors['required']) {
    <div class="mt-1 text-sm text-red-500">Required</div>
    } @else if(ctrl.errors['error_msg']) {
    <div class="mt-1 text-sm text-red-500">{{ ctrl.errors['error_msg'] }}</div>
    }
    } @if(subtext !== "") {
    <div class="mt-1 text-sm text-slate-500 dark:text-zink-200 dark:text-white">
      {{ subtext }}
    </div>
    }
  </div>
</div>