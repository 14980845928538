import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(payload: any) {
    return this.http.post(environment.host + '/auth.php', {
      gofor: 'login',
      ...payload,
    });
  }

  refreshToken(refresh_token: string) {
    return this.http.post(environment.host + '/auth.php', {
      gofor: 'refresh_token',
      refresh_token: refresh_token,
    });
  }

  isTokenExpired(jwtToken: string) {
    return this.http.post(environment.host + '/auth.php', {
      gofor: 'is_token_expired',
      jwtToken: jwtToken,
    });
  }

  logout(refresh_token: string) {
    return this.http.post(environment.host + '/auth.php', {
      gofor: 'logout',
      refresh_token: refresh_token,
    });
  }
}
