export enum InputSizeConfig {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export interface IconConfig {
  visibility: boolean;
  name: string;
  direction: string;
}

export enum BorderStyleConfig {
  DEFAULT = 'default',
  TRANSPARENT = 'transparent',
  DASHED = 'dashed',
}

export enum BorderStyleShape {
  DEFAULT = 'default',
  ROUNDED = 'rounded',
}

export interface BorderConfig {
  style: BorderStyleConfig;
  shape: BorderStyleShape;
}

export interface BorderConfig {
  style: BorderStyleConfig;
  shape: BorderStyleShape;
}

export interface GroupConfig {
  visibility: boolean;
  icon: string;
  direction: string;
}

export class BaseCustomInput {
  constructor(
    private icon: IconConfig = {
      visibility: false,
      name: 'mail',
      direction: 'left',
    },
    private border: BorderConfig = {
      style: BorderStyleConfig.DEFAULT,
      shape: BorderStyleShape.DEFAULT,
    },
    private size: InputSizeConfig = InputSizeConfig.MD,
    private group: GroupConfig = {
      visibility: false,
      icon: '&#64;',
      direction: 'left',
    }
  ) {}
}
